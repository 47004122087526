<template>
  <div class="search-tag-report d-flex flex-column align-center col-search">
    <div class="tags-container mb-1">
      <v-chip
        v-for="(tagSelected, index) in filter.searchTags"
        :key="`tag-selected-chip-${index}`"
        class="ma-1"
        color="teal"
        text-color="white"
        small
        label
        close
        @click:close="unSelectTag(index)">
        {{ tagSelected }}
      </v-chip>
    </div>
    <div class="d-flex align-center col-search">
      <v-text-field
        v-model="filter.search"
        class="mr-2"
        height="40"
        hide-details
        dense
        solo>
        <template v-slot:append>
          <v-icon x-small>
            {{ appendIcon }}
          </v-icon>
        </template>
      </v-text-field>
      <v-autocomplete
        v-model="selectedTags"
        :items="autoCompleteTags"
        :search-input.sync="tagSearch"
        height="40"
        multiple
        filled
        solo
        hide-details
        hide-selected>
        <template v-slot:append>
          <v-icon x-small>
            mdi-tag
          </v-icon>
        </template>
        <template v-slot:selection>
          <span></span>
        </template>
      </v-autocomplete>
      <div class="tags-operation pl-2">
        <span class="text-caption mr-2">OR</span>
        <v-switch
          v-model="filter.tagOperator"
          class="ma-0"
          false-value="OR"
          true-value="AND"
          color="secondary"
          hide-details
          dense />
        <span class="text-caption">AND</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        search: '',
        searchTags: [],
        tagOperator: 'OR'
      })
    },
    appendIcon: {
      type: String,
      default: null
    },
    tags: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tagSearch: ''
    }
  },
  computed: {
    autoCompleteTags () {
      return this.tags.map((tag) => tag.name)
    },
    selectedTags: {
      get () {
        return this.filter.searchTags
      },
      set (val) {
        this.filter.searchTags = val
        this.tagSearch = ''
      }
    },
    filter: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    unSelectTag (index) {
      const tagIndex = this.tags.findIndex((tag) => tag.name === this.filter.searchTags[index])
      if (tagIndex !== -1) {
        this.filter.searchTags.splice(index, 1)
      }
    }
  }

}
</script>

<style scoped>
.col-search {
  width: 100%;
}
.search-tag-report {
  width: 100%;
}
.tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.tags-operation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.search-tag-report .v-input__control {
  max-width: unset !important;
  min-height: 40px !important;
  max-height: 40px !important;
}
.search-tag-report .v-input__slot {
  max-width: unset !important;
  min-height: 40px !important;
  max-height: 40px !important;
}
</style>
