var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.mappedHeaders,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"detail-box-image",attrs:{"src":_vm.avoidNullValue(item, 'image', null)},on:{"mouseout":function($event){return _vm.previewImg(null)},"mouseover":function($event){_vm.previewImg(_vm.avoidNullValue(item, 'image', null))}}})]}},{key:"item.productCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.avoidNullValue(item, 'productCode', '?'))+" ")]}},{key:"item.sketchId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.avoidNullValue(item, 'sketchId', '-'))+" ")]}},{key:"item.designer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.avoidNullValue(item, 'designer', '-'))+" ")]}},{key:"item.productName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.avoidNullValue(item, 'productName', ''))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.avoidNullValue(item, 'type', '-'))+" ")]}},{key:"item.classification",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.avoidNullValue(item, 'classification', '-'))+" ")]}},{key:"item.stockIn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.avoidNullValue(item, 'stockIn', '0'))+" ")]}},{key:"item.sale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.avoidNullValue(item, 'sale', '0'))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(_vm.avoidNullValue(item, 'price', '0')))+" ")]}},{key:"item.ratio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(_vm.avoidNullValue(item, 'ratio', '0.00')))+" ")]}},{key:"item.ngp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(_vm.avoidNullValue(item, 'ngp', '0')))+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.makeAllAlphabetUpperCase(_vm.avoidNullValue(item, 'size', '?')))+" ")]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fisrtAlphabetUpperCase(_vm.avoidNullValue(item, 'color', '?')))+" ")]}},{key:"item.saleThrough",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showNumberFormat(_vm.avoidNullValue(item, 'saleThrough', '0.00'), 2))+"% ")]}},_vm._l((_vm.branchesHeaderMap(_vm.branchHeaders)),function(branch){return {key:("item." + (branch.value)),fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.avoidNullValue(item, branch.value, '0 / 0'))+" ")]}}})],null,true)}),_c('div',{staticClass:"preview-image"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.previewEnabled),expression:"previewEnabled"}],attrs:{"src":_vm.currentImgPreview}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }