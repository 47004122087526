<template>
  <section class="report-header">
    <v-row
      justify="space-between"
      class="ma-0">
      <v-col
        cols="10"
        class="d-flex align-center date-picker-col">
        <search-by-tags-report
          v-model="filter"
          append-icon="mdi-magnify"
          :tags="tags" />
      </v-col>
      <v-spacer />
      <v-col
        cols="2"
        class="d-flex justify-end date-picker-col">
        <v-btn
          class="report-btn"
          color="secondary"
          :loading="loading"
          @click="findClicked()">
          ค้นหา
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      justify="space-between"
      class="ma-0">
      <v-col
        cols="5"
        class="d-flex align-center date-picker-col">
        <date-range-picker
          v-model="value.dates"
          label="Stocks In" />
      </v-col>
      <v-col
        cols="5"
        class="d-flex align-center date-picker-col">
        <date-range-picker
          v-model="value.saleDates"
          label="Sales" />
      </v-col>
      <v-spacer />
      <v-col
        cols="2"
        class="d-flex justify-end date-picker-col">
        <v-btn
          class="report-btn"
          color="secondary"
          :loading="loading"
          @click="exportClicked()">
          export ({{ exportLength }})
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SearchByTagsReport from '@/components/SearchByTagsReport.vue'
import DateRangePicker from '@/components/DateRangePicker.vue'

const ProductAttributeService = new ProductAttributeProvider()

export default {
  components: {
    DateRangePicker,
    SearchByTagsReport
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      default: false,
      type: Boolean
    },
    exportLength: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      tags: []
    }
  },
  computed: {
    filter: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  created () {
    this.getTags()
  },
  methods: {
    async getTags () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setErrorPage(error.code)
      }
    },
    exportClicked () {
      this.$emit('export-click')
    },
    findClicked () {
      this.$emit('find')
    }
  }
}
</script>

<style scoped>
  .report-header {
    position: sticky;
    top: 50px;
    z-index: 5;
    background-color: #ffffff;
    box-shadow: 1px 2px 5px 0px #c2c2c2;
  }
  .report-btn {
    max-width: 120px;
    width: 100%;
  }
  .search-tags {
    width: 100%;
  }
</style>
