import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class MerReportProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MER_API)
  }

  getReportSizesWithColors (payload) {
    this.setHeader(getAuthToken())

    return this.get('/report/color-and-size', payload)
  }

  getReportColors (payload) {
    this.setHeader(getAuthToken())

    return this.get('/report/color', payload)
  }
}

export default MerReportProvider
