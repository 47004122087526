<template>
  <div>
    <v-data-table
      :headers="mappedHeaders"
      :items="items"
      :loading="loading">
      <template #[`item.image`]="{ item }">
        <img
          :src="avoidNullValue(item, 'image', null)"
          class="detail-box-image"
          @mouseout="previewImg(null)"
          @mouseover="previewImg(avoidNullValue(item, 'image', null))" />
      </template>
      <template #[`item.productCode`]="{ item }">
        {{ avoidNullValue(item, 'productCode', '?') }}
      </template>
      <template #[`item.sketchId`]="{ item }">
        {{ avoidNullValue(item, 'sketchId', '-') }}
      </template>
      <template #[`item.designer`]="{ item }">
        {{ avoidNullValue(item, 'designer', '-') }}
      </template>
      <template #[`item.productName`]="{ item }">
        {{ avoidNullValue(item, 'productName', '') }}
      </template>
      <template #[`item.type`]="{ item }">
        {{ avoidNullValue(item, 'type', '-') }}
      </template>
      <template #[`item.classification`]="{ item }">
        {{ avoidNullValue(item, 'classification', '-') }}
      </template>
      <template #[`item.stockIn`]="{ item }">
        {{ avoidNullValue(item, 'stockIn', '0') }}
      </template>
      <template #[`item.sale`]="{ item }">
        {{ avoidNullValue(item, 'sale', '0') }}
      </template>
      <template #[`item.price`]="{ item }">
        {{ avoidNullValue(item, 'price', '0') | showFullPriceFormat() }}
      </template>
      <template #[`item.ratio`]="{ item }">
        {{ avoidNullValue(item, 'ratio', '0.00') | showFullPriceFormat() }}
      </template>
      <template #[`item.ngp`]="{ item }">
        {{ avoidNullValue(item, 'ngp', '0') | showFullPriceFormat() }}
      </template>
      <template #[`item.size`]="{ item }">
        {{ makeAllAlphabetUpperCase(avoidNullValue(item, 'size', '?')) }}
      </template>
      <template #[`item.color`]="{ item }">
        {{ fisrtAlphabetUpperCase(avoidNullValue(item, 'color', '?')) }}
      </template>
      <template #[`item.saleThrough`]="{ item }">
        {{ showNumberFormat(avoidNullValue(item, 'saleThrough', '0.00'), 2) }}%
      </template>
      <template
        v-for="(branch) in branchesHeaderMap(branchHeaders)"
        #[`item.${branch.value}`]="{ item }">
        {{ avoidNullValue(item, branch.value, '0 / 0') }}
      </template>
    </v-data-table>
    <div class="preview-image">
      <img
        v-show="previewEnabled"
        :src="currentImgPreview" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      headers: {
        type: Array,
        required: true
      },
      hasSize: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array,
        required: true
      },
      branchHeaders: {
        type: Array,
        required: true
      },
      loading: {
        type: Boolean,
        required: false
      }
    },
    data () {
      return {
        previewEnabled: false,
        currentImgPreview: null
      }
    },
    computed: {
      headersLength () {
        return this.headers.length
      },
      mappedHeaders () {
        return this.initMappedHeader(this.headers, this.branchHeaders)
      }
    },
    methods: {
      previewImg (img) {
        this.currentImgPreview = img
        this.previewEnabled = !!this.currentImgPreview
      },
      initMappedHeader (headers, branches) {
        const branchHeaders = branches.map((branch) => ({ text: branch.name, value: `branch-${branch.id}`, align: 'center' }))

        return [...headers, ...branchHeaders]
      },
      branchesHeaderMap (branches) {
        return branches.map((branch) => ({ text: branch.name, value: `branch-${branch.id}`, align: 'center' }))
      },
      headerAlignCompute (header) {
        return header.align || 'text-center'
      },
      contentCompute (item, header) {
        return item[header.value] || '-'
      },
      avoidNullValue (object, properties, defaultValue = '-') {
        return object[properties] || defaultValue
      },
      fisrtAlphabetUpperCase (string) {
        return `${string[0].toUpperCase() + string.slice(1)}`
      },
      makeAllAlphabetUpperCase (string) {
        return string.toUpperCase()
      },
      showNumberFormat (number = 0, _float = 0) {
        const num = parseFloat(number)
        const float = num % 1 === 0 ? 0 : _float
        if (num === 0 || num) return parseFloat(number).toFixed(float).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return '-'
      }
    }
  }
</script>

<style scoped>
.preview-image {
  z-index: 999;
  max-width: 35%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.preview-image img {
  border: 3px solid #333333;
  padding: 3px;
  border-radius: 15px;
}
img.detail-box-image {
  cursor: pointer;
  max-height: 80px;
  transition: transform 0.1s;
}
img.detail-box-image:active {
  transform: scale(0.95);
}
</style>
